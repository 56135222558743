

.change-password-container-main {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  height: 100vh;
  background-color: #d1ecf0;
}

.nav-logo-change {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.changepassword-logo {
  max-width: 150px;
  height: auto;
  margin: 40px;
}

.change-password-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.change-password-card {
  border-radius: 8px;
  width: 400px;
}
.container-box-change-password{
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.change-password-heading{
  color: #0c5c75;
}

.container-box-change-password,
.change-password-container{
  position: relative;
}
.change-password-container::before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #e8e8e8;
  height: 100%;
}
@media (max-width: 767px) {
  .change-password-container{
    background: white;
    border-radius: 8px;
  }
  .container-box-change-password{
    background: transparent;
  }
  .change-password-container::before{
    content: none;
  }
}


.change-password-form {
  display: flex;
  flex-direction: column;
}

.form-group-change-password {
  margin-bottom: 15px;
  text-align: left;
}

.form-group-change-password label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group-change-password input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.change-password-button {
  background-color: rgb(12, 92, 117);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  font-weight: 600;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
}

.change-password-button:hover {
  background-color: rgb(7, 70, 88);
}
