/** @format */

.group {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
  max-width: 190px;
}

.input {
  height: 40px;
  line-height: 28px;
  padding: 0 1rem;
  width: 100%;
  padding-left: 2.5rem;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  background-color: #d9e8d8;
  color: #0d0c22;
  box-shadow: 0 0 5px #c1d9bf, 0 0 0 10px #f5f5f5eb;
  transition: 0.3s ease;
}

input::placeholder {
  color: #777;
}

.icon {
  position: absolute;
  left: 1rem;
  fill: #777;
  width: 1rem;
  height: 1rem;
}

#nav-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1000px;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
  transform: translate3d(0, 0, 0);
  z-index: 5;
}

.Main_Nav {
  /* backdrop-filter: blur(4px);
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: auto;
  margin: 0 30px;
  margin-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
  align-items: center;
  border-radius: 10px; */
  padding: 10px 20px;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 6px 6px 0px,
  rgba(0, 0, 0, 0.03) 0px -10px 10px 2px;
  background-color: #fff;
  position: fixed;
  z-index: 9;
  top:0;
  left: 0;
  right: 0;
  border: 0;
}

@media (max-width: 820px) {
  .Main_Nav {
    margin: 0px 0px;
    padding: 10px;
  }
}

.Main_Nav .content {
  display: flex;
  /* flex: 1; */
  gap: 10px;
  justify-content: flex-end;
}

.Main_Nav .Search {
  position: relative;
  /* width: 100%; */
  z-index: 9;
}

.Main_Nav .Employee_title {
  margin: 0;
  font-size: 16px;
}

.Main_Nav .Search input {
  background-color: #fff;
  border: 1px solid #e2e8f0;
  outline: none;
  width: 100%;
  color: #000;
  border-radius: 6px;
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
}

.Main_Nav .Search .Search_results {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: inherit;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 0;
  border-radius: 10px;
  height: 110px;
  overflow-y: auto;

}

.Main_Nav .Search .Search_results ul li a {
  background-color: #fff;
  padding: 0.375rem 0.75rem;
  color: #0d0c22;
  text-decoration: none;
  display: block;
  border-bottom: solid 1px #eee;
}

.Main_Nav .Search .Search_results .close_btn {
  display: flex;
  justify-content: flex-end;
}

.Main_Nav .Search .Search_results .close_btn i {
  font-size: 16px;
  cursor: pointer;
}

.Main_Nav .Search .Search_results ul,
.Main_Nav .Search .Search_results ul li {
  padding: 0;
  margin: 0;
}

.Main_Nav img {
  margin: 0;
}

.profile_img {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 100%;
  object-fit: cover;
}

.page-title-bar .fa-bars,
.Main_Nav .content .fa-bars,
.headerWithArrow .fa-bars {
  cursor: pointer;
  font-size: 16px;
  display: none;
}

/* @media (max-width: 1180px) {

  .page-title-bar .fa-bars,
  .headerWithArrow .fa-bars,
  .Main_Nav .content .fa-bars {
    display: block;
  }
} */

/* input.navbar--input_search::placeholder {
  color: white;
} */

.user-profile-dropdown .profile-dropdown-btn {
  background-color: #fff !important;
  border: solid 1px #D9D9D9 !important;
  border-radius: 100px;
  padding: 6px 36px 6px 6px !important;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 6px 6px 0px,
  rgba(0, 0, 0, 0.03) 0px -10px 10px 2px;
  position: relative;
}

.user-profile-dropdown .profile-dropdown-btn .profile_img {
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 100%;
  object-fit: cover;
  border: solid 1px #0082C0;
}


.user-profile-dropdown .profile-dropdown-btn .nav-user-name {
  color: #3D3D3D;
  font-weight: 600;
}

.user-profile-dropdown .dropdown-toggle::after {
  position: absolute;
  right: 15px;
  top: 50%;
  color: #000;
}
@media screen and (max-width:767px) {
  .user-profile-dropdown .dropdown-toggle::after{
    display: none;
  }
  .user-profile-dropdown .profile-dropdown-btn {
    padding: 0 !important;
    border: none !important;
    box-shadow: none;
  }
  /* .Main_Nav .profile-dropdown-btn .nav-user-image .profile_img {
    width: auto!important;
    height: auto!important;
    max-width: 100%;
  } */
}

@media (min-width: 1180px) {
  .side-toggle-btn {
    display: none;
  }
}
.nav-menu-icon {
  font-size: 24px;
}
.profile-dropdown-menu .nav-user-image{
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 100%;
  border: solid 1px #0082C0;
}
.profile-dropdown-menu .nav-user-name
{
  font-size: 13px;
  color: #2F2E41;
  font-weight: 600;
}
.profile-dropdown-menu .nav-user-email{
  color: #6E7585;
  font-size: 12px;
}
.profile-dropdown-menu .dropdown-item:focus .nav-user-name,
.profile-dropdown-menu .dropdown-item:focus .nav-user-email,
.profile-dropdown-menu .dropdown-item:focus{
  color: #ffffff;
}
.profile-dropdown-menu .dropdown-item:focus{
  background-color: #0082C0;
}
.profile-dropdown-menu .dropdown-item svg{
  font-size: 20px;
}
.profile-dropdown-menu .nav-user-image .profile_img{
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}
.notify-indicator{
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: #F44336;
  border: solid 1px #F44336;
  position: absolute;
  top: 2px;
  right: 0px;
}
.navbar-links-icons {
  width: 46px;
  height: 46px;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFCFC;
  border: solid 1px #F0F0F0;
  position: relative;
  margin-right: 20px;
  color: #158596;
}
.navbar-icons{
  border-right: solid 1px #d9d9d9;
  margin-right: 15px;
}
@media screen and (max-width: 767px) {
  .navbar-icons{
    margin-right: 0px;
  }
  .navbar-links-icons{
    margin-right: 10px;
  }
  .navbar-links-icons {
    width: 36px;
    height: 36px;
  }
  .nav-menu-icon {
    font-size: 18px;
  }
  .Main_Nav .nav-left-bar a img{
    max-height: 40px !important;
  }
}