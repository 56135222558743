section.mh-100vh {
    min-height: 100vh;
}

.dashboard-bg {
    position: relative;
    z-index: 1;
}

.dashboard-bg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('./../../public/dashboard-bg.png');
    background-size: cover;
    background-position: left;
    opacity: 0.3;
    z-index: -1;
    transform: scaleX(-1);
}

.outer-sidebar {
    /* height: 100vh; */
    width: 170px;
}

.right-content {
    margin-left: 190px;
}

@media (max-width: 1179px) {
    .outer-sidebar {
        display: none;
    }

    .right-content {
        margin-left: 0px;
        margin-top: 70px;
    }
}

@media (min-width:1181px) {
    .right-content {
        margin-left: 200px;
        margin-top: 65px;
    }

    .outer-sidebar {
        width: 190px;
    }
}

@media (min-width: 1179px) {
    .outer-sidebar {
        position: fixed;
        top: 75px;
        left: 0px;
        bottom: 0px;
        /* height: calc(100% - 20px); */
        z-index: 9;
        /* background-color: #1a9fb2; */
        background: #37afb5;
        background: linear-gradient(350deg, #1a9fb2 0%, #37afb5 100%);
        background: -webkit-linear-gradient(350deg, #1a9fb2 0%, #37afb5 100%);
        background: -moz-linear-gradient(350deg, #1a9fb2 0%, #37afb5 100%);
        border-radius: 0px;
        padding: 20px 0px;
    }

    .outer-siderbar-nav {
        height: calc(100% - 10px);
        overflow-y: auto;
        overflow-x: hidden;
        /* margin-top: 1.5rem; */
    }
}

.menu-item {
    display: flex;
    /* flex-direction: column; */
    /* justify-content: center; */
    align-items: center;
    padding: 10px;
    cursor: pointer;
    /* margin-bottom: 30px; */
    font-size: 14px;
    gap: 10px;
    transition: all 0.6s ease-in-out;
    -webkit-transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
}
.menu-item svg{
    font-size: 22px;
    color: #fff;
    flex-shrink: 0;
}


.menu-item.active {
    /* background-color: #dbe6e74a;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin: auto; */
    /* margin-bottom: 30px; */
    color: #fff !important;
    background-color: #0f7e8e;
}

/* .menu-item.active .icon1, .menu-item svg {
    color: #fff;
} */

.text {
    font-size: 15px;
    text-align: left;
    color: #fff;
    /* display: none; */
}
.menu-item:hover .text,
.menu-item:hover svg,
.menu-item.active svg,
.menu-item.active .text{
    color: #ffffff;
    transition: all 0.6s ease-in-out;
    -webkit-transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
}
/* .menu-item.active .text {
    text-align: center;
    display: block;
    border-radius: 100px;
} */

.menu-item .icon1,
.logouticon .icon1 {
    font-size: 14px;
    margin-bottom: 5px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logouticon {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 30px;
}

.iconLogout {
    font-size: 25px;
}

.app-verion-text {
    position: absolute;
    bottom: 15px;
    left: 15px;
    right: 15px;
    text-align: center;
    font-size: 14px;
    color: #C5DCE7;
    font-weight: 500;
}